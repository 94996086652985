@import 'variables.css';
@import 'animations.css';

/* Reset & Base Styles */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: var(--font-sans);
  background: var(--color-background);
  color: var(--color-text-primary);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: var(--space-4);
}

h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
  letter-spacing: -0.02em;
}

h2 {
  font-size: clamp(2rem, 4vw, 3rem);
  letter-spacing: -0.01em;
}

h3 {
  font-size: clamp(1.5rem, 3vw, 2rem);
}

p {
  margin-bottom: var(--space-4);
  color: var(--color-text-secondary);
}

a {
  color: var(--color-accent);
  text-decoration: none;
  transition: color var(--transition-fast);
}

a:hover {
  color: var(--color-accent-light);
}

/* Utility Classes */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--space-4);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Section Styles */
.section {
  padding: var(--space-8) 0;
}

/* Grid System */
.grid {
  display: grid;
  gap: var(--space-4);
}

@media (min-width: 768px) {
  .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Fix mobile menu issues */
.navigation__mobile {
  display: none;
}

@media (max-width: 768px) {
  .navigation__mobile.is-open {
    display: block;
  }
  html {
    font-size: 14px;
  }
}

/* Add transition styles */
body {
  transition: background-color 0.3s ease, color 0.3s ease;
}

* {
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

:focus-visible {
  outline: 2px solid var(--color-accent);
  outline-offset: 2px;
}

section {
  position: relative;
  pointer-events: auto;
}

section:not(.is-in-view) {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (max-width: 768px) {
  section:not(.is-in-view) {
    opacity: 0.95;
    transition: opacity 0.3s ease;
  }
}

@media (max-width: 768px) {
  .hero {
    padding-top: var(--space-2xl);
  }

  .hero__content {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 3rem;
  }

  .hero__text {
    order: 2;
  }

  .hero__visual {
    order: 1;
  }
}

.hero__content {
  padding-top: 4rem;
}

/* Add this to your global styles */
.section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 4rem;
  text-align: center;
  color: var(--color-text-primary);
}

.section-title svg {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-accent);
}
