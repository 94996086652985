.footer {
  padding: var(--space-xl) 0;
  background: var(--color-background-alt);
  border-top: 1px solid var(--color-border);
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-sm);
  text-align: center;
}

.footer__text,
.footer__copyright {
  font-size: var(--text-sm);
  color: var(--color-text-secondary);
}
