:root {
  --transition-fast: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-base: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-slow: 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-in {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity var(--transition-base), transform var(--transition-base);
}

.fade-in.is-visible {
  opacity: 1;
  transform: translateY(0);
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn var(--transition-standard) forwards;
}

.scale-in {
  animation: scaleIn var(--transition-standard) forwards;
}

/* Stagger children animations */
.stagger-children > * {
  opacity: 0;
  animation: fadeIn var(--transition-standard) forwards;
}

.stagger-children > *:nth-child(1) { animation-delay: 100ms; }
.stagger-children > *:nth-child(2) { animation-delay: 200ms; }
.stagger-children > *:nth-child(3) { animation-delay: 300ms; }
.stagger-children > *:nth-child(4) { animation-delay: 400ms; }
.stagger-children > *:nth-child(5) { animation-delay: 500ms; }

@media (prefers-reduced-motion: reduce) {
  :root {
    --transition-fast: 0ms;
    --transition-base: 0ms;
    --transition-slow: 0ms;
  }

  section {
    transition: none;
  }
}

.experience-card-wrapper {
  transition-delay: calc(var(--index) * 100ms);
}

@media (prefers-reduced-motion: reduce) {
  .experience-card-wrapper {
    transition: none;
    opacity: 1;
    transform: none !important;
  }
}
