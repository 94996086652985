.back-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: var(--color-foreground);
  color: var(--color-text-primary);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transform: translateY(1rem);
  transition: all 0.3s ease;
  z-index: 900;
}

.back-to-top.is-visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.back-to-top:hover {
  background: var(--color-background-alt);
  color: var(--color-accent);
  border-color: var(--color-accent);
  transform: translateY(-4px);
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 1.5rem;
    right: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }
} 