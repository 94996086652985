.contact {
  padding: var(--space-5xl) 0;
  scroll-margin-top: var(--nav-height);
  background: var(--color-background);
}

.contact__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--space-xl);
  padding: var(--space-4xl) 0;
}

.contact__title {
  font-size: var(--text-3xl);
  font-weight: 700;
  color: var(--color-text-primary);
}

.contact__description {
  font-size: var(--text-lg);
  color: var(--color-text-secondary);
  max-width: 600px;
  margin: 0 auto;
}

.contact__links {
  display: flex;
  gap: var(--space-lg);
  margin-top: var(--space-lg);
}

.contact__link {
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  padding: var(--space-md) var(--space-lg);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  color: var(--color-text-primary);
  text-decoration: none;
  transition: var(--transition-base);
  background: var(--color-foreground);
}

.contact__link:hover {
  transform: translateY(-2px);
  border-color: var(--color-accent);
  color: var(--color-accent);
}

.contact__link svg {
  font-size: var(--text-xl);
}

@media (max-width: 768px) {
  .contact__links {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }

  .contact__link {
    justify-content: center;
  }
}
  
.contact__resume-btn {
  display: flex;
  align-items: center;
  gap: var(--space-sm);
  padding: var(--space-md) var(--space-lg);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  background: var(--color-foreground);
  color: var(--color-text-primary);
  font-size: var(--text-base);
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition-base);
  width: 100%;
  justify-content: center;
}

.contact__resume-btn:hover {
  transform: translateY(-2px);
  border-color: var(--color-accent);
  color: var(--color-accent);
}
