.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background);
  overflow: hidden;
}

.hero__content {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 2rem;
  align-items: center;
  padding-top: calc(var(--nav-height) + 2rem);
}

.hero__text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.hero__greeting {
  font-family: var(--font-mono);
  font-size: 1.125rem;
  color: var(--color-accent);
  opacity: 0.9;
}

.hero__title {
  font-size: 5rem;
  font-weight: 700;
  line-height: 1;
  background: linear-gradient(
    45deg,
    var(--color-text-primary),
    var(--color-accent)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
}

.hero__role {
  display: block;
  font-size: 3rem;
  color: var(--color-text-secondary);
  margin-top: 1rem;
  -webkit-text-fill-color: var(--color-text-secondary);
}

.hero__description {
  font-size: 1.25rem;
  line-height: 1.6;
  color: var(--color-text-secondary);
  max-width: 540px;
}

.hero__image-wrapper {
  position: relative;
  width: 100%;
  max-width: 280px;
  margin-left: auto;
}

.hero__image-container {
  position: relative;
  z-index: 1;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;
  transform: perspective(1000px) rotateY(-5deg);
  transition: transform 0.3s ease;
  border: 1px solid var(--color-border);
}

.hero__image-container:hover {
  transform: perspective(1000px) rotateY(0deg);
}

.hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.hero__image-container:hover .hero__image {
  filter: grayscale(0%);
}

.hero__image-backdrop {
  position: absolute;
  inset: 0.5rem -0.5rem -0.5rem 0.5rem;
  background: var(--color-accent);
  border-radius: 12px;
  opacity: 0.1;
  z-index: 0;
}

@media (max-width: 912px) {
  .hero__content {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 3rem;
  }

  .hero__text {
    order: 2;
  }

  .hero__description {
    margin: 0 auto;
  }

  .hero__title {
    font-size: 3.5rem;
  }

  .hero__role {
    font-size: 2.5rem;
  }

  .hero__image-wrapper {
    max-width: 250px;
    margin: 0 auto;
  }
}