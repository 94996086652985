/* Global styles */
body {
  margin: 0;
  font-family: var(--font-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  color: var(--color-text-primary);
}

/* Smooth transitions for theme changes */
body * {
  transition: background-color var(--transition-standard),
              color var(--transition-standard),
              border-color var(--transition-standard);
}

/* Focus styles */
:focus-visible {
  outline: 2px solid var(--color-accent);
  outline-offset: 2px;
}

/* Selection styles */
::selection {
  background-color: var(--color-accent);
  color: white;
}

/* Add consistent scroll margin for all sections */
section[id] {
  scroll-margin-top: calc(var(--nav-height) + 0.5rem);
}
