:root {
  --font-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  --nav-height: 4rem;
  --border-radius: 8px;
  --transition-base: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  --space-xs: 0.25rem;   /* 4px */
  --space-sm: 0.5rem;    /* 8px */
  --space-md: 1rem;      /* 16px */
  --space-lg: 1.5rem;    /* 24px */
  --space-xl: 2rem;      /* 32px */
  --space-2xl: 3rem;     /* 48px */
  --space-3xl: 4rem;     /* 64px */
  --space-4xl: 5rem;     /* 80px */
  --space-5xl: 6rem;     /* 96px */
  --text-xs: 0.75rem;    /* 12px */
  --text-sm: 0.875rem;   /* 14px */
  --text-base: 1rem;     /* 16px */
  --text-lg: 1.125rem;   /* 18px */
  --text-xl: 1.25rem;    /* 20px */
  --text-2xl: 1.5rem;    /* 24px */
  --text-3xl: 2rem;      /* 32px */
  --color-foreground-rgb: 255, 255, 255;
  --color-border-rgb: 0, 0, 0;
}

/* Light theme */
:root[data-theme="light"] {
  --color-background: #ffffff;
  --color-foreground: #f8f9fa;
  --color-background-alt: #f1f3f5;
  --color-text-primary: #171717;
  --color-text-secondary: #737373;
  --color-accent: #404040;
  --color-border: #e5e5e5;
}

/* Dark theme */
:root[data-theme="dark"] {
  --color-background: #111111;
  --color-background-alt: #1a1a1a;
  --color-foreground: #1e1e1e;
  --color-text-primary: #ffffff;
  --color-text-secondary: #a0a0a0;
  --color-accent: #a3a3a3;
  --color-border: #333333;
  --color-foreground-rgb: 30, 30, 30;
  --color-border-rgb: 255, 255, 255;
}
