:root {
  --nav-height: 4rem;
}

.navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 1.25rem 2rem;
    background: var(--color-background);
    transition: all 0.3s ease;
    height: var(--nav-height);
    display: flex;
    align-items: center;
  }
  
  .navigation.is-scrolled {
    background: var(--color-background);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid var(--color-border);
  }
  
  .navigation__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .navigation__logo {
    display: flex;
    align-items: center;
  }
    
  .navigation__logo-image {
    height: 24px;
    width: auto;
    filter: invert(0%);
    transition: filter var(--transition-base);
  }
  
  :root[data-theme="dark"] .navigation__logo-image {
    filter: invert(100%);
  }
  
  .navigation__desktop {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  
  .navigation__link {
    font-size: 0.9375rem;
    color: var(--color-text-primary);
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .navigation__link:hover {
    color: var(--color-accent);
  }
  /* Mobile Menu Button */
  .navigation__mobile-toggle {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .navigation__mobile-toggle span {
    width: 100%;
    height: 2px;
    background: var(--color-text-primary);
    transition: all 0.3s ease;
  }
  
  .navigation__mobile-toggle.is-active span:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }
  
  .navigation__mobile-toggle.is-active span:nth-child(2) {
    opacity: 0;
  }
  
  .navigation__mobile-toggle.is-active span:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
  
  /* Mobile Navigation */
  .navigation__mobile {
    display: none;
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    background: var(--color-background);
    padding: 1.5rem;
    border-bottom: 1px solid var(--color-border);
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.3s ease;
  }
  
  .navigation__mobile.is-open {
    transform: translateY(0);
    opacity: 1;
  }
  
  .navigation__mobile-link {
    display: block;
    padding: 1rem;
    color: var(--color-text-primary);
    text-decoration: none;
    font-size: 1.125rem;
    text-align: center;
    transition: color 0.2s ease;
  }
  
  .navigation__mobile-link:hover {
    color: var(--color-accent);
  }
  
  .navigation__mobile-resume {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    padding: 1rem;
    border: 1px solid var(--color-border);
    background: var(--color-foreground);
    color: var(--color-text-primary);
    font-size: 0.9375rem;
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition-base);
  }
  
  .navigation__mobile-resume:hover {
    border-color: var(--color-accent);
    color: var(--color-accent);
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .navigation__desktop {
      display: none;
    }
  
    .navigation__mobile-toggle {
      display: flex;
      height: 18px;
      margin-left: 1rem;
    }
  
    .navigation__mobile {
      display: block;
      top: var(--nav-height);
    }
  }
  
  .navigation__theme-toggle {
    background: var(--color-foreground);
    border: none;
    color: var(--color-text-primary);
    font-size: 1.25rem;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    transition: all 0.2s ease;
  }
  
  .navigation__theme-toggle:hover {
    background: var(--color-background-alt);
    color: var(--color-accent);
    transform: translateY(-2px);
  }
  
  .navigation__mobile-controls {
    display: none;
    gap: 1rem;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .navigation__mobile-controls {
      display: flex;
    }
  
    .navigation__desktop .navigation__theme-toggle {
      display: none;
    }
  }