.card {
  position: relative;
  border-radius: var(--border-radius);
  background: var(--color-foreground);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card--glass {
  background: rgba(var(--color-foreground-rgb), 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(var(--color-border-rgb), 0.1);
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -1px rgba(0, 0, 0, 0.03),
    0 1px 0 rgba(255, 255, 255, 0.1) inset;
}

/* Add hover effect */
.card--glass:hover {
  box-shadow: 
    0 10px 20px -5px rgba(0, 0, 0, 0.08),
    0 4px 6px -2px rgba(0, 0, 0, 0.05),
    0 1px 0 rgba(255, 255, 255, 0.1) inset;
}

/* Light mode specific adjustments */
@media (prefers-color-scheme: light) {
  .card--glass {
    background: rgba(var(--color-foreground-rgb), 0.85);
    box-shadow: 
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06),
      0 0 0 1px rgba(0, 0, 0, 0.05);
  }

  .card--glass:hover {
    box-shadow: 
      0 10px 20px -5px rgba(0, 0, 0, 0.12),
      0 4px 6px -2px rgba(0, 0, 0, 0.08),
      0 0 0 1px rgba(0, 0, 0, 0.05);
  }
}