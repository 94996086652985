.projects {
  perspective: 1000px;
  padding: var(--space-3xl) 0;
  opacity: 1;
  transition: opacity var(--transition-base);
}

.projects.is-blurred {
  opacity: 0.85;
}

.projects__title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 4rem;
  text-align: center;
  color: var(--color-text-primary);
}

.projects__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.project-card-wrapper {
  position: relative;
}

.project-item {
  height: 100%;
  position: relative;
  background: var(--color-foreground);
  border-radius: var(--border-radius);
  overflow: hidden;
  isolation: isolate;
  border: 1px solid var(--color-border);
  transform: perspective(1000px) rotateY(-2deg);
  transition: transform 0.3s ease;
}

.project-item:hover {
  transform: perspective(1000px) rotateY(0deg);
}

.project-item__thumbnail {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background);
  border-bottom: 1px solid var(--color-border);
  padding: 2rem;
}

.project-item__thumbnail img {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  transition: transform 0.3s ease;
  filter: invert(0%);
  transition: filter var(--transition-base);
}

:root[data-theme="dark"] .project-item__thumbnail img {
  filter: invert(100%);
}

.project-item:hover .project-item__thumbnail img {
  transform: scale(1.05);
}

.project-item__backdrop {
  position: absolute;
  inset: 0.5rem -0.5rem -0.5rem 0.5rem;
  background: var(--color-accent);
  border-radius: var(--border-radius);
  opacity: 0.1;
  z-index: -1;
}

.project-item__content {
  padding: 1.5rem;
}

.project-item__content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}

.project-item__description {
  color: var(--color-text-secondary);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.project-item__highlights {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem 0;
}

.project-item__highlights li {
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 0.5rem;
  color: var(--color-text-secondary);
}

.project-item__highlights li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: var(--color-accent);
}

.project-item__tech {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1.5rem 0;
  padding-top: 1.5rem;
  border-top: 1px solid var(--color-border);
}

.project-item__link {
  display: inline-flex;
  align-items: center;
  color: var(--color-accent);
  text-decoration: none;
  font-weight: 500;
  transition: transform 0.2s ease;
}

.project-item__link:hover {
  transform: translateX(4px);
}

@media (max-width: 768px) {
  .projects {
    padding: 4rem 1.5rem;
  }

  .projects__title {
    font-size: 1.75rem;
    margin-bottom: 3rem;
  }

  .projects__grid {
    grid-template-columns: 1fr;
  }
}